.container-color-legend{
    margin-top: 10px
 }
 .title{
     margin-bottom: -10px;
     margin-left: 50px;
 }
 .legend-ul{
     list-style-type: none;
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     /* margin-right:10px */
 }
 
 .legend-colorBox {
     width: 1.5rem;
     height: 1.5rem;
     display: inline-block;
     background-color: blue;
     margin-right: 5px;
     border-radius: 6px;
 }
 