:root {
  --main-color: #b40d0d;
  --secondary-color: #2c3e50;
  --main-button-color: #2c3e50;
  --main-button-enabled-hover-color: #1e2b37;
  --white-color: white;
  --green-color: #018001;
  --border-radius: 8px !important;
  --main-button-cancel-color: #b40d0d;
}

#tabellaLogin {
  border: 1px light gray solid;
  padding: 20px;
  text-align: center;
  box-shadow: 0px 0px 24px -2px #9e9e9e;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.divFooter {
  position: fixed;
  z-index: -1;
  left: 0;
  bottom: 0;
  width: 99%;
  margin: 10px;
  text-align: left;
  margin: 10px;
  color: #999999;
}

a {
  text-decoration: none;
}

.p-img-menubar {
  width: 380px !important;
}

.p-menubar {
  background-color: var(--white-color) !important;
}

.p-menuitem-link {
  outline: none !important;
  /* box-shadow: 0 0 0 0.2rem #172667 !important; */
}

.p-menuitem-link:focus {
  outline: none !important;
  /* box-shadow: 0 0 0 0.2rem var(--main-button-cancel-color) !important;; */
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  /* background: var(--main-button-cancel-color) !important;; */
  outline: 0 none;
  outline-offset: 0;
  /* box-shadow: inset 0 0 0 0.2rem var(--main-button-cancel-color) !important;; */
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: var(--main-color) !important;
  color: var(--white-color) !important;
  border-color: #333333 !important;
  outline: none !important;
  box-shadow: 0 0 0 0.2rem var(--main-color) !important;
  border-radius: var(--border-radius) !important;
}

.p-button-warning {
  background: var(--white-color) !important;
  border: #c1c1c1 !important;
  box-shadow: 0 0 0 0.2rem #c1c1c1 !important;
}

.p-button-secondary {
  background: var(--main-color) !important;
  border: 1px solid var(--white-color) !important;
}

.p-tabmenu {
  box-shadow: inset 0 0 0 0.2rem var(--main-color) !important;
}

td.p-frozen-column {
  width: 55px !important;
}

.headerButton {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.p-confirm-dialog .p-button.p-button-text {
  background-color: var(--main-button-cancel-color) !important;
}

/* Forgroud color of calendar buttons*/
/* .p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text, .p-fileupload-choose.p-button-secondary.p-button-text{
  color: white;
} */

.p-tabview .p-tabview-panels {
  padding: 5px !important;
}

.p-dropdown {
  border-radius: 6px;
}

.dropDownForFilter {
  min-width: 200px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--main-color) !important;
  border-color: var(--white-color) !important;
  color: var(--white-color);

  border-radius: var(--border-radius) !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: var(--main-color) !important;
  border-color: var(--white-color) !important;
  color: var(--white-color);

  border-radius: var(--border-radius) !important;
}

.p-calendar-w-btn-right .p-datepicker-trigger {
  background: var(--main-button-color) !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-tabview .p-tabview-panels {
  color: var(--white-color) !important;
}

/* Header Table */
.tabPannel {
  background: rgb(51 51 51) !important;
  padding: 0.72845rem 0.85rem !important;
}

.currentPageTitle {
  margin-left: 15px;
  color: var(--main-color) !important;
}

.headerCurrent {
  margin-top: 3px;
}

/* Label Button */
.p-button-label {
  color: var(--white-color) !important;
}

/* Style menu header */
.newMenu.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  background: var(--main-color) !important;
  border: 1px solid var(--main-color) !important;
  border-color: var(--main-color) !important;
  padding: 1em 0.5em 1em;
  color: var(--white-color) !important;
  font-weight: 500;
  transition: background-color 0.2s, box-shadow 0.2s;
  margin: 0 0 -1px 0;
  height: calc(100% + 1px);
}

.newMenu.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: var(--secondary-color) !important;
  font-weight: 700;
  transition: background-color 0.2s, box-shadow 0.2s;
  margin: 0 0 -1px 0;
  height: calc(100% + 1px);
}

.newMenu .p-menuitem-link:hover {
  background-color: var(--main-color) !important;
  color: var(--secondary-color) !important;
}

.newMenu.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
}

.newMenu.p-tabmenu-nav a {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;
  overflow: hidden;
}

.newMenu.p-tabmenu .p-tabmenu-nav {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  align-items: stretch;
  background-color: var(--main-color) !important;
}

.p-tabmenu {
  box-shadow: inset 0 0 0 0.2rem #ffffff00;
}

.actions-config-dialog {
  width: 55rem !important;
  height: 660px !important;
}

.eventDetailsDialog {
  width: 800px !important;
  min-width: 550px !important;
  min-height: 480px !important;
}

.event-detail-container {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
}

.event-detail-container .p-badge {
  background: var(--white-color) !important;

  color: var(--main-color) !important;
}

.event-detail-container .p-accordion-header-text {
  width: 100%;
}

.event-detail-container .p-card-body {
  border: 1px solid lightgray;
  box-shadow: 5px 6px 5px 0px rgba(117, 116, 116, 0.503) !important;
}

.event-detail-main-info {
  width: 50%;
  border: 1px solid black;
  border-radius: var(--border-radius) !important;
  padding: 1rem;
}

.event-detail-accordion {
  margin-top: 20px;
  min-width: 30rem;
  width: 100%;
}

.event-detail-container .p-card-content {
  display: flex;
  justify-content: space-around;
}

.td-event-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event-detail-spec-tag {
  margin: 0.3rem;
}

.event-detail-status-tag {
  margin: 0.3rem;
}

.panel-appender-decl-container-dr {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 1rem;
}

@media (max-width: 1350px) {
  .panel-appender-decl-container-dr {
    flex-wrap: wrap;
  }
}

.panel-appender-decl-container-dd {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 1rem;
}

.panel-appender-decl-cont-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 1rem;
}

.panel-appender-decl-filter-field {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 0.5rem;
}

.panel-appender-decl-filter-container {
  display: flex;
  flex-flow: wrap;
  margin-left: 0.5rem;
  row-gap: 0.5rem;
  margin-bottom: 1rem;
}

.colorLegendDialog {
  width: 330px !important;
  height: 340px !important;
}

.AppointmentClosureDialog {
  width: 250px !important;
  height: 420px !important;
}

.labelAppointment {
  padding: 1px;
  color: black;
}

/* override button color **/
.p-button {
  background: var(--main-button-color);
  color: var(--white-color) !important;
  border-color: var(--main-button-color);
  border-radius: var(--border-radius) !important;
}

.p-button:enabled:hover {
  background: #1e2b37;
  color: var(--white-color) !important;
  border-color: #1a252f;
}

.p-button:enabled:hover {
  background: var(--main-button-enabled-hover-color);
  color: var(--white-color) !important;
  border-color: var(--main-button-enabled-hover-color);
}

.header-button {
  color: var(--white-color) !important;
  background: none;
  border: none;
}

.header-button .p-button:enabled:hover {
  color: var(--secondary-color) !important;
}

.notification-icon {
  color: var(--white-color) !important;
}

.cpc-delete-button {
  background: var(--main-button-cancel-color) !important;
  color: var(--white-color) !important;
  border-color: var(--main-button-cancel-color) !important;
}

.cpc-delete-button:hover {
  background: #810a0a !important;
  color: var(--white-color) !important;
  border-color: #810a0a !important;
}

.cpc-table-custom-button {
  background: var(--main-button-color) !important;
  color: var(--white-color) !important;
  border-color: var(--main-button-color) !important;
}

.cpc-table-custom-button:hover {
  background: var(--main-button-enabled-hover-color) !important;
  color: var(--white-color) !important;
  border-color: var(--main-button-enabled-hover-color) !important;
}

.panel-cancel-custom-button {
  background: var(--main-button-cancel-color) !important;
  color: var(--white-color) !important;
  border-color: var(--main-button-cancel-color) !important;
}

.panel-cancel-custom-button:hover {
  background: #810a0a !important;
  color: var(--white-color) !important;
  border-color: #810a0a !important;
}

.up-psw-closed-button {
  background: var(--main-button-cancel-color) !important;
  color: var(--white-color) !important;
  border-color: var(--main-button-cancel-color) !important;
}

.up-psw-closed-button:hover {
  background: #810a0a !important;
  color: var(--white-color) !important;
  border-color: #810a0a !important;
}

/* .cpc-edit-button {
  background: var(--main-button-color) !important;
  color: var(--white-color)!important;
  border-color: var(--main-button-color) !important;
}  */

/* --fc-button-text-color: #fff;
--fc-button-bg-color: #2c3e50;
--fc-button-border-color: #2c3e50;
--fc-button-hover-bg-color: #1e2b37;
--fc-button-hover-border-color: #1a252f;
--fc-button-active-bg-color: #1a252f;
--fc-button-active-border-color: #151e27 */

/* LCSTemplate styles **/
.header-logo {
  max-width: 12rem;
  height: unset !important;
  cursor: pointer;
  border-radius: 6px;
  margin-left: 15px;
}

.header {
  border-bottom: 1px solid;
  border-color: #d7d5d5;
  padding: 5px;
  background-color: var(--main-color) !important;
  /* flex-direction: column; */
}

.order-import .cfi-container {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  gap: 0.5rem !important;
}

.p-dialog-mask .p-dialog-center .p-component-overlay .p-component-overlay-enter .p-dialog-visible .p-dialog-draggable .p-dialog-resizable {
  z-index: 1 !important;
}

.p-inputnumber {
  display: flex;
}

.cfi-container {
  display: flex !important;
  gap: 0.5rem !important;
  align-content: space-between;
  align-content: center;
  align-items: flex-start;
  flex-direction: column !important;
}

.cfi-field {
  display: flex !important;
  width: 100% !important;
}

.cpcid-dialog {
  width: 80vw;
  padding: 3%;
}

/* SHFullCalendar event classnames **/
.fc {
  background-color: white;
}

.shfc-violation-idon {
  border: 3px solid var(--main-button-cancel-color) !important;
}

.shfc-violation-idon div {
  color: black;
}

.shfc-violation-sovr {
  border: 3px solid var(--main-button-cancel-color) !important;
}

.shfc-violation-sovr div {
  color: black;
}

.shfc-violation-escl {
  border: 3px solid var(--main-button-cancel-color) !important;

}

.shfc-violation-escl div {
  color: white;
}

.op-spinner {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.op-spinner:before {
  content: "";
  display: block;
  position: fixed;
  top: 3.2rem;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.53);
}

.newEventsPanel {
  background-color: var(--secondary-color) !important;
  border: 1px solid rgb(186, 182, 182);
  margin-right: 3vh;
  border-radius: var(--border-radius) !important;
  padding: 5px;
  width: 25%;
  display: flex;
  flex-direction: column;
}

/* .newEventsPanelLabel {
  font-weight: bold;
  text-align: center;
  color: white;
  height: 70vh;
} */

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.5em;
  margin-top: 1.5em;
  margin-left: 9px;
  margin-right: 9px;
}

.containerEvent {
  display: flex;
  /* width: 100%; */
  align-items: center;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  scrollbar-width: thin;
  /* Not supported by Safari */
  scrollbar-color: var(--secondary-color) var(--secondary-color) !important;
  /* Not supported by Safari */
  align-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  padding-left: 0.8rem;
  padding-right: 0.2rem;
}

/* Stilizzazione della scrollbar per WebKit */
.containerEvent::-webkit-scrollbar {
  width: 5px;
}

.containerEvent::-webkit-scrollbar-track {
  background: var(--secondary-color);
}

.containerEvent::-webkit-scrollbar-thumb {
  background: var(--main-color) !important;
  border-radius: var(--border-radius) !important;
  margin: 20px;
}

.containerEvent::-webkit-scrollbar-thumb:hover {
  background: var(--main-button-enabled-hover-color) !important;
}

.fixedHeader {
  font-weight: bold;
  text-align: center;
  color: white;
  margin-top: 29px;
}

.event {
  width: 100%;
  color: white !important;
  margin-bottom: 1vh;
  flex-direction: column;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--main-color) !important;
  border-radius: var(--border-radius) !important;
}

.panel-reg-div-row {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2px;
  /* flex-wrap: wrap; */
}

.panel-reg-input {
  width: 70%;
  margin-bottom: 2px;
}

.card-register {
  padding-top: 20vh;
  width: 40%;
  margin-left: 30%;
}

.register-button {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 2px;
}

.p-togglebutton.p-button.p-highlight {
  background: var(--main-button-color) !important;
  border-color: var(--main-button-color) !important;
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight) {
  background: #f3f2f1;
  border-color: #605e5c;
  color: black;
}

.p-checkbox .p-checkbox-box.p-highlight {
  background: var(--main-button-color) !important;
  border-color: var(--main-button-color) !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--main-button-color) !important;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background-color: var(--main-button-color) !important;
}

.form_mail_editor {
  width: 100%;
}

.dialog-resource-info {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.dialog-resource-info .p-card-body {
  height: 300px;
  box-shadow: 0.5rem 0.5rem 0.5rem 0.5rem rgba(117, 116, 116, 0.503) !important;
}

.dialog-resource-spec {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  min-width: 15rem;
}

.chart-w-full {
  width: 20rem !important;
  height: 20rem !important;
}

.agenda-resource-button {
  margin-left: 0.5rem;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25em;
}

.spec-body {
  height: 9.3rem;
}

.spec-body-column {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.spec-body-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.andamento-body {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
}

.button-multi-resource {
  margin-top: 1vw;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.table-competenze {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  align-content: center;
}

.fc .fc-button-primary {
  background-color: var(--main-button-color) !important;
  border-color: var(--main-button-color) !important;
}

.fc .fc-button-primary:hover {
  background-color: var(--main-button-enabled-hover-color) !important;
}

.containerLogin .p-card-body {
  background-color: var(--secondary-color) !important;
}

.p-dialog-header {
  border-top-right-radius: var(--border-radius) !important;
  border-top-left-radius: var(--border-radius) !important;
}

.p-dialog-footer {
  border-bottom-right-radius: var(--border-radius) !important;
  border-bottom-left-radius: var(--border-radius) !important;
}

.div-form-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.menu_sidebar {
  list-style-type: none;
  padding: 0;
}

.menu_item {
  cursor: pointer;
  padding: 10px;
  color: var(--white-color) !important;
}

.menu_item.active {
  background-color: var(--main-color) !important;
  color: white !important;
  border-radius: var(--border-radius) !important;
}

.menu_item i {
  margin-right: 8px;
}

.p-confirm-popup-accept {
  background-color: var(--main-button-cancel-color) !important;
}

.p-button.p-button-danger:enabled:focus {
  box-shadow: none;
}

.p-inputtext {
  border-radius: var(--border-radius) !important;
  padding: 10px;
}

.p-tag.p-tag-danger {
  border-radius: var(--border-radius) !important;
}

.p-tag.p-tag-success {
  background-color: var(--green-color) !important;
  color: var(--white-color) !important;
  border-radius: var(--border-radius) !important;
}

.p-tag.p-tag-warning {
  background-color: #fa4;
  color: var(--white-color) !important;
  border-radius: var(--border-radius) !important;
}

.panel-appender-decl-cont-details {
  background-color: white;
  border-radius: var(--border-radius) !important;
}

.panel-appender-decl-cont-details .row-totals {
  background-color: white;
  border-radius: var(--border-radius) !important;
}

.panel-form-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.panel-table-header {
  border: 1px solid lightgray;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  flex-wrap: wrap;
}

.panel-table-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5rem;
}

.panel-table-header-dropdown {
  padding: 0.2rem 0.2rem;
}

.containerMailComp {
  min-width: -webkit-fill-available;
  height: 100%;
  border: 1px solid lightgrey;
  background: white;
  border-radius: var(--border-radius) !important;
}

.sidebar_dashboard {
  position: fixed;
  width: 20%;
  background-color: var(--secondary-color) !important;
  border-radius: 6px;
  padding: 0.5em;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: var(--main-color) !important;
  border-color: var(--withe-color) !important;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1rem;
  border: 1px solid #a19f9d;
  color: var(--white-color) !important;
  background: #b40d0d6a !important;
  font-weight: 600;
  border-radius: 2px;
  transition: box-shadow 0.2s;
  border-radius: var(--border-radius) !important;
}

.p-accordion .p-accordion-content {
  padding: 1rem;
  border: none;
  border-top: none;
  border-bottom-right-radius: var(--border-radius) !important;
  border-bottom-left-radius: var(--border-radius) !important;
}

.container_dashboard {
  --topbar-height: 50px;
  --sidebar-width: 100px;
  color: white;
  margin-top: 1rem;
}

.scrollable_content_dashboard {
  margin-left: 21%;
}

/*ANIMATION*/

.sidebar_dashboard {
  display: none;
  /* Nascondi il div inizialmente */
}

.sidebar_dashboard.visible {
  display: block;
  /* Mostra il div quando è visibile */
}

.scrollable_content_dashboard {
  display: none;
  /* Nascondi il div inizialmente */
}

.scrollable_content_dashboard.visible {
  display: block;
  /* Mostra il div quando è visibile */
}

.menu_sidebar {
  list-style-type: none;
  padding: 0;
}

.menu_item {
  cursor: pointer;
}

.menu_item.active {
  font-weight: bold;
}

.signinbutton {
  background: var(--green-color) !important;
  width: 100%;
  font-weight: bold;
  margin-right: 1vh;
}

.p-inputtext:enabled:focus {
  box-shadow: none;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: none;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0 !important;
}

.headerDialogDetails {
  display: flex;
  justify-content: space-between;
}

.event-detail-status-tag {
  border-radius: var(--border-radius) !important;
}

.p-chip .p-chip-text {
  line-height: 1.5;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  margin-left: 3px;
}