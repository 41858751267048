body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
 /*  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */

  /*  background-color: #E0E0E0;*/
}

/* h1 {
  font-family: 'Copperplate', 'Arial';
  color: grey;
} */

@media screen and (max-width: 992px) {
  .card-register {
    padding-top: 20vh !important;
    width: 40% !important;
    margin-left: 30% !important;
  }
  
}

@media screen and (max-width: 600px) {
  .card-register {
    padding-top: 20vh;
    width: 80% !important;
    margin-left: 10% !important;
  }

}
