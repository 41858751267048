.containerStopLigth {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  padding-top: 1.5rem;
  padding-bottom: 50px;
}

.stoplightRed {
  background-color: transparent;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  box-shadow: 0 0 30px #cc3232, inset 0 0 15px #cc3232;
  -webkit-keyframes: pulse 2s linear 1s infinite;
  color: black;
}

/*EFFETTO LAMPEGGIANTE*/
.stoplightRedActive {
  background-color: transparent;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  box-shadow: 0 0 30px #cc3232, inset 0 0 15px #cc3232;
  color: black;
  animation: pulse 0.5s infinite;
}

@keyframes pulse {

  0%,
  100% {
    box-shadow: 0 0 250px#cc3232, inset 0 0 25px #cc3232;
  }

  50% {
    box-shadow: 0 0 225px rgba(204, 50, 50, 0.5),
      inset 0 0 15px rgba(204, 50, 50, 0.5);
  }
}

.stoplightYellow {
  background-color: transparent;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  box-shadow: 0 0 30px #e7b416, inset 0 0 15px #e7b416;
  -webkit-keyframes: pulse 2s linear 1s infinite;
  color: black;
}

/*EFFETTO LAMPEGGIANTE*/
.stoplightYellowActive {
  background-color: transparent;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  box-shadow: 0 0 30px #e7b416, inset 0 0 15px #e7b416;
  color: black;
  animation: pulse 0.5s infinite;
}

@keyframes pulse {

  0%,
  100% {
    box-shadow: 0 0 30px #e7b416, inset 0 0 25px #e7b416;
  }

  50% {
    box-shadow: 0 0 30px rgba(204, 50, 50, 0.1),
      inset 0 0 15px rgba(204, 50, 50, 0.1);
  }
}

.stoplightGreen {
  background-color: transparent;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  box-shadow: 0 0 30px #2dc937, inset 0 0 15px #2dc937;
  -webkit-keyframes: pulse 2s linear 1s infinite;
  color: black;
}

.numberStopLigth {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 40px;
}

.container_button {
  background-color: white;
  padding: 0.5rem;
}

.legend {
  display: flex;
  justify-content: flex-end;
  margin: 1rem;
}

.legend-item {
  display: flex;
  align-items: center;
}

.legend-color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 0.5rem;
  position: relative;
  cursor: pointer;
}

/* .containerStopLigth {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
background-color: #333;

padding: 10px;
border-radius: 10px;
}

.stoplightRed,
.stoplightYellow,
.stoplightGreen {
  width: 50px;

  height: 50px;
  border-radius: 50%;
  margin: 10px 0;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 1.2em;
}

.stoplightRedActive {
  background-color: red;
}

.stoplightYellowActive {
  background-color: yellow;
  color: black;
}

.stoplightGreen {
  background-color: green;
}

.numberStopLigth {
  display: none;
}

.stoplightRedActive .numberStopLigth,
.stoplightYellowActive .numberStopLigth,
.stoplightGreen .numberStopLigth {
  display: block;
}

*/