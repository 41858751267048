.calendar_input {
  margin-left: 10px;
  margin-top: 20px;
  width: 250px;
}

.container {
  background-color: white;
  margin-right: 15px;
  margin-top: 20px;
}

.messageErrorRange {
  margin-left: 10px;
  margin-top: 10px;
  width: 250px;
}
